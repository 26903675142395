import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FaAngleDoubleLeft, FaTrash } from "react-icons/fa";
import ChatInterface from '../components/ChatInterface';
import { Room } from "../interfaces/Interfaces";
import { useUser } from "../providers/UserProvider";

const QnAChat = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<Room>();
  const [title, setTitle] = useState<string>("");
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const roomsListRef = useRef<HTMLUListElement>(null);
  const { user } = useUser();

  const fetchRooms = async () => {
    const accessToken = localStorage.getItem('access_token');
    try {
      if (user) {
        const response = await axios.get(`https://jrlchatfi.atxlabs.ai/copilot/api/fetch-rooms/${user?.user_id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        setRooms(response.data);
      }
    } catch (err) {
      console.error("Error fetching recommendations:", err);
    }
  };

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  useEffect(() => { fetchRooms() }, [user]);

  useEffect(() => { setIsCollapsed(true) }, [selectedRoom])

  useEffect(() => {

    if (roomsListRef.current) {
      roomsListRef.current.scrollTo({
        top: roomsListRef.current.scrollHeight,
        behavior: "smooth"
      })
    }

  }, [rooms])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (title.trim() === "") {
      alert("Please enter a valid title.");
      return;
    }

    try {
      const token = localStorage.getItem('access_token');

      const getCSRFToken = () => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; csrftoken=`);
        if (parts.length === 2) return parts!.pop()!.split(';').shift();
      };

      const response = await axios.post(
        'https://jrlchatfi.atxlabs.ai/copilot/api/new-room/',
        { title, user_id: user?.user_id },
        {
          headers: {
            'X-CSRFToken': getCSRFToken() || '',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }, withCredentials: true
        }
      );
      if (response.status === 201) fetchRooms();
    } catch (error) {
      console.error("Error submitting recommendation:", error);
    }
  };


  const QAMessageAPI = "https://jrlchatfi.atxlabs.ai/copilot/api/messages/"
  const QAheader = "ChatFi"


  const handleDelete = async (roomid: string) => {
    const token = localStorage.getItem('access_token');
    if (!window.confirm("Are you sure you want to delete this room?")) return;
    setDeleting(true);
    try {
      const getCSRFToken = () => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; csrftoken=`);
        if (parts.length === 2) return parts!.pop()!.split(';').shift();
      };

      await axios.delete(`https://jrlchatfi.atxlabs.ai/copilot/api/delete-room/`, {
        data: { roomid },
        headers: {
          'X-CSRFToken': getCSRFToken() || '',
          'Authorization': `Bearer ${token}`
        }, withCredentials: true
      });
      fetchRooms();
    } catch (err) {
      console.error("Error deleting room:", err);
      alert("Failed to delete room. Please try again later.");
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="flex h-full">
      <div
        className={`pl-2 fixed h-full md:static bg-customDarkGrey backdrop-blur-sm bg-opacity-50 transition-all duration-300 ease-in-out ${isCollapsed ? 'w-0 opacity-0' : 'w-screen md:w-96 opacity-100'} overflow-hidden`}
      >
        <div className="mr-20 md:mr-0 p-8 bg-customDarkGrey h-full">
          <form onSubmit={handleSubmit} className="space-y-4 mb-4">
            <div className="flex flex-col">
              <label className="text-center md:text-left block text-white mb-3">Title:</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="border p-2 rounded"
                placeholder="Room Title"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-customGreen text-white px-4 py-2 mt-4 rounded hover:bg-customLightGreen"
            >
              Create
            </button>
          </form>
          {rooms.length === 0 ? (
            <p className="text-gray-500 text-center">No Existing Rooms.</p>
          ) : (
            <ul className="space-y-6 overflow-y-auto max-h-[50vh] flex-col scroll-smooth" ref={roomsListRef}>
              {rooms.map((room) => (
                <li
                  key={room.id}
                  className="bg-customAlmostBlack rounded-lg shadow-md p-4 flex justify-between"
                >
                  <div
                    className={`cursor-pointer transition-colors duration-200 ${selectedRoom && selectedRoom.id === room.id ? "text-customGreen" : "text-white"}`}
                    onClick={() => setSelectedRoom(room)}
                  >
                    <h3 className="text-lg font-bold mb-2">{room.title}</h3>
                  </div>
                  <button
                    className="text-red border border-red-400 px-2 mx-2 rounded transition-colors duration-200"
                    onClick={() => handleDelete(room.id)}
                    disabled={deleting}
                  >
                    <FaTrash color="#FF6969" />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="z-10  text-white flex flex-col justify-center h-full mx-2">
        <div onClick={toggleSidebar} className="px-1 py-6 border-[1px] border-gray-800 rounded">
          <FaAngleDoubleLeft className={`transition-transform duration-300 ${isCollapsed ? 'rotate-180' : ''}`} />
        </div>
      </div>
      <div className="flex-1 -ml-6 -mt-4">
        {selectedRoom ?
          <ChatInterface currentApiChatInterface={QAMessageAPI} room={selectedRoom} />
          :
          <div className="h-full flex justify-center items-center">
            <h1 className="text-gray-500">Please Select a Room.</h1>
          </div>
        }
      </div>
    </div>
  );
};

export default QnAChat;
