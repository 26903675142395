// src/pages/Login.tsx
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../services/AuthServices'

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  const handleLogin = async () => {
    setLoading(true)
    setError(null)
    try {
      await login(username, password)
      navigate('/home', { replace: true })
      window.location.reload()
    } catch (error) {
      setError('Login failed: Incorrect credentials or inactive account.')
    } finally {
      setLoading(false)
    }
  }


  return (
    <section className="bg-customDarkGrey min-h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center px-4 py-8 mx-auto w-full sm:max-w-lg md:max-w-lg lg:max-w-xl md:h-auto">
        <div className="w-full rounded-lg shadow border border-gray-700 bg-customLightGrey">
          <div className="p-6 space-y-4 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-white">
              Login
            </h1>
            {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
            <label className="block mb-2 text-sm font-medium text-gray-200 text-gray-200">Username</label>
            <input
              type="text"
              placeholder="Username"
              value={username}
              className=" border border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 bg-customDarkGrey bg-customDarkGrey border-gray-600  placeholder-gray-400 text-white focus:ring-green-500 focus:border-green-500"
              onChange={(e) => setUsername(e.target.value)}
            />

            {/* Password Input */}
            <label className="block mb-2 text-sm font-medium text-gray-200">Password</label>
            <input
              type="password"
              placeholder="••••••••"
              value={password}
              className=" border   rounded-lg  block w-full p-2.5 bg-customDarkGrey bg-customDarkGrey border-gray-600 border-gray-600 placeholder-gray-400 placeholder-gray-400 text-white text-white focus:ring-green-500 focus:ring-green-500 focus:border-green-50 focus:border-green-500"
              onChange={(e) => setPassword(e.target.value)}
            />

            <button
              onClick={handleLogin}
              disabled={loading}
              className={`w-full text-white   hover:text-white focus:ring-1 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-customGreen hover:bg-customLightGreen focus:ring-green-800 ${loading ? 'bg-customLightGreen' : 'bg-customGreen hover:bg-customLightGreen'
                }`}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
