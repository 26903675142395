import React, { useState } from 'react';
import { RecommendationData2 } from '../interfaces/Interfaces';
import { formatDateTimeSmart } from '../utils';

interface RecommendationDetailsProps {
    recommendation: any
}
const RecommendationDetails: React.FC<RecommendationDetailsProps> = ({ recommendation }) => {


    const parsedData: Record<string, RecommendationData2> = JSON.parse(recommendation.data);

    Object.keys(parsedData).forEach((k) => {
        const step = parsedData[k]
        if (typeof step.Data === "string") {
            step.Data = JSON.parse(step.Data)
        }
    })


    const steps = Object.values(parsedData);
    const lastStep = steps.at(-1);
    const remainingSteps = steps.slice(0, -1);


    const [currentStep, setCurrentStep] = useState<RecommendationData2 | null>(steps[0]);
    const [isTableView, setIsTableView] = useState<boolean>(false);

    const handleStepClick = (step: RecommendationData2) => {
        setCurrentStep(step);
    };

    const TableComponent = ({ currentStep }: { currentStep: any }) => {
        return (
            <div className="overflow-auto custom-scrollbar w-full h-full flex">
                {typeof currentStep.Data === "object" &&
                    <table className="min-w-full bg-customLightGrey rounded-lg">
                        <thead>
                            <tr className="bg-customAlmostBlack text-white">
                                <th className="py-2 px-4 border-b text-left">Sr No.</th>
                                {Object.keys(currentStep.Data).map((key) => {
                                    return <th className="py-2 px-4 border-b text-left" key={key}>{key}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: Object.keys(currentStep.Data[Object.keys(currentStep.Data)[0]]).length || 0 }).map((_, index) => (
                                <tr key={index} className="bg-customAlmostBlack text-white">
                                    <td className="py-2 px-4 border-b">{index + 1}</td>
                                    {Object.keys(currentStep.Data).map((key, i) => {
                                        return <td className="py-2 px-4 border-b" key={i}>{Object.values(currentStep.Data[key])[index] as string}</td>;
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        );
    };


    return (
        <div className="p-8 bg-customGrey h-screen flex flex-col mx-auto overflow-auto custom-scrollbar max-h-screen">
            <h2 className="text-3xl font-bold text-white mb-3 mt-4">{recommendation.reconname}</h2>
            <p className='text-white'><span className="font-semibold">Category:</span> {recommendation.type}</p>
            <p className='text-white'><span className="font-semibold">Created At:</span> {formatDateTimeSmart(recommendation.datetime.toString())}</p>
            <p className='text-white'><span className="font-semibold">Data Source:</span> {recommendation.sheet}</p>

            {/* Final Recommendations Section */}
            <div className="mb-5 mt-2 bg-customLightGrey p-3 rounded-lg">
                <h3 className="text-xl font-bold text-white mb-2">Final Recommendations:</h3>
                <div className="flex-col w-full">
                    {lastStep && lastStep.Reason && <p className='text-white'>{lastStep.Reason}</p>}
                    <div className="pr-4 flex-1 mt-2">
                        <div className="bg-customAlmostBlack rounded-lg shadow-md p-4 h-full">
                            <div>
                                <TableComponent currentStep={lastStep} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="hidden md:block">
                <div className="flex items-center mt-4">
                    <div className='ml-auto flex'>
                        <span className="mr-3 text-white">
                            Data View
                        </span>

                        <label className="relative inline-flex items-center cursor-pointer 1">
                            <input
                                type="checkbox"
                                checked={isTableView}
                                onChange={() => setIsTableView(!isTableView)}
                                className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-customGreen peer-focus:ring-2 peer-focus:ring-customLightGreen transition duration-300"></div>
                            <div
                                className="absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full transform peer-checked:translate-x-5 transition duration-300"
                            ></div>
                        </label>
                        <span className="ml-3  text-white">
                            Flow View
                        </span>
                    </div>
                </div>

                {/* Remaining Steps and Current Step Details Section */}
                <div className="flex justify-between mb-5 flex-grow max-h-50">
                    {isTableView ? (
                        <div className="overflow-auto custom-scrollbar w-full">
                            <h3 className="text-xl font-bold text-white mb-2">Recommendation Flow:</h3>
                            <table className="min-w-full bg-customLightGrey rounded-lg">
                                <thead>
                                    <tr className="bg-customAlmostBlack text-white">
                                        <th className="text-left py-2 px-4 border-b">Sr No.</th>
                                        <th className="text-left py-2 px-4 border-b">Step</th>
                                        <th className="text-left py-2 px-4 border-b">Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {remainingSteps.map((step, index) => (
                                        <tr key={index} className="bg-customAlmostBlack text-white">
                                            <td className="py-2 px-4 border-b">{index + 1}</td>
                                            <td className="py-2 px-4 border-b">{step.Step}</td>
                                            <td className="py-2 px-4 border-b">{step.Reason}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="w-1/4 pr-4 ">
                            <h3 className="text-xl font-bold text-white mb-2">Steps:</h3>
                            <ul className="custom-scrollbar overflow-scroll max-h-[80vh]">
                                {remainingSteps.map((step, index) => (
                                    <li key={index} className={`rounded-lg shadow-md p-0.5 ${step.Step === currentStep?.Step && "bg-customGreen"} mb-2`}>
                                        <div className="bg-customAlmostBlack rounded-lg shadow-md p-4">
                                            <p className='text-white'><span className="font-semibold">Step {index + 1}:</span> {step.Step}</p>
                                            <button
                                                className="text-customGreen hover:underline mt-4"
                                                onClick={() => handleStepClick(step)}
                                            >
                                                View Details
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Current Criteria Details Section */}
                    {/* Hide current criteria details when in table view */}
                    {!isTableView && currentStep && (
                        <div className="w-3/4 overflow-auto custom-scrollbar max-h-[80vh]">
                            <h3 className="text-xl font-bold text-white mb-2">Current Criteria Details:</h3>
                            <div className="bg-customAlmostBlack rounded-lg shadow-md p-4 mb-6">
                                <h2 className='text-white mb-2 font-semibold'>{currentStep.Step}</h2>
                                {currentStep.Reason && <p className='text-white'><span className='font-semibold'>Reason:</span> {currentStep.Reason}</p>}
                                {currentStep.Data && (
                                    <div className="shadow-md overflow-y-auto">
                                        <span className="text-white mb-4 font-semibold">Data: </span>
                                        {(typeof currentStep.Data === "string" || typeof currentStep.Data === "number") && currentStep.Data.toString()}
                                        <TableComponent currentStep={currentStep} />
                                    </div>
                                )}

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecommendationDetails;
