import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RecommendationFormProps } from '../interfaces/Interfaces';
import { useUser } from '../providers/UserProvider';

const RecommendationForm: React.FC<RecommendationFormProps> = ({ onNewRecommendation }) => {
    const [type, setType] = useState<string>("");
    const [reconName, setReconName] = useState<string>("");
    const [supportedSheets, setSupportedSheets] = useState<string[]>([]);
    const [selectedSheet, setSelectedSheet] = useState<string>("");
    const [supportedCategories, setSupportedCategories] = useState<string[]>([]);

    const token = localStorage.getItem("access_token");

    const { user } = useUser();

    useEffect(() => {
        const fetch_supported_sheets = async () => {
            try {
                const response = await axios.get('https://jrlchatfi.atxlabs.ai/copilot/api/fetch-supported-sheets/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                setSupportedSheets(response.data.data || []);
            } catch (error) {
                console.error("Error fetching supported sheets:", error);
            }
        };
        const fetch_supported_categories = async () => {
            try {
                const response = await axios.get('https://jrlchatfi.atxlabs.ai/copilot/api/fetch-supported-rec-categories/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                setSupportedCategories(response.data.data || []);
            } catch (error) {
                console.error("Error fetching supported sheets:", error);
            }
        };
        if (token) {
            fetch_supported_sheets()
            fetch_supported_categories()
        }
    }, [token]);

    const handleCategorySelect = (option: string) => {
        setType(option);
    };

    const handleSheetSelect = (option: string) => {
        setSelectedSheet(option);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!selectedSheet) {
            alert("Please select a sheet.");
            return;
        }

        try {
            const getCSRFToken = () => {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; csrftoken=`);
                if (parts.length === 2) return parts!.pop()!.split(';').shift();
            };
            const response = await axios.post(
                'https://jrlchatfi.atxlabs.ai/copilot/api/generate-recommendations/',
                { type, reconName, selectedSheet, user_id: user?.user_id },
                {
                    headers: {
                        'X-CSRFToken': getCSRFToken() || '',
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );

            if (response.status === 201) {
                const newRecommendation = response.data;
                onNewRecommendation(newRecommendation);
            }
        } catch (error) {
            console.error("Error submitting recommendation:", error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4 mb-4">
            <div className="flex flex-col">
                <label className="block text-white mb-3">Name:</label>
                <input
                    type="text"
                    value={reconName}
                    onChange={(e) => setReconName(e.target.value)}
                    className="border p-2 rounded"
                    placeholder="Enter recommendation name"
                    required
                />
            </div>
            <div className="relative">
                <label className="block text-white mb-3">Category:</label>
                <select
                    className="border w-full p-2 rounded cursor-pointer bg-white text-customAlmostBlack"
                    value={type}
                    onChange={(e) => handleCategorySelect(e.target.value)}
                >
                    <option value="" disabled>
                        Select a category
                    </option>
                    {supportedCategories.map((option, index) => (
                        <option key={index} value={option} className="text-black">
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div className="relative">
                <label className="block text-white mb-3">Sheet:</label>
                <select
                    className="border w-full p-2 rounded cursor-pointer bg-white text-customAlmostBlack"
                    value={selectedSheet}
                    onChange={(e) => handleSheetSelect(e.target.value)}
                    required
                >
                    <option value="" disabled>
                        Select a sheet
                    </option>
                    {supportedSheets.map((option, index) => (
                        <option key={index} value={option} className="text-black">
                            {option.split(" ").pop()?.slice(0, -5) || option}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex justify-end mt-2">
                <button
                    type="submit"
                    className="w-full bg-customGreen text-white px-4 py-2 mt-4 rounded hover:bg-customLightGreen"
                >
                    Recommend
                </button>
            </div>
        </form>
    );
};

export default RecommendationForm;
