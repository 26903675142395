
import backgroundSVG from '../assets/ATXLabsBackground.svg';

const Dashboard: React.FC = () => {



  return (
    <div className="flex-1 p-8 bg-[#1a1a1a] h-full">
      <div
        className="mx-auto w-3/4 flex flex-col justify-center items-center h-full text-center"
        style={{
          backgroundImage: `url(${backgroundSVG})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '100%',
        }}
      >
        <h1 className="text-5xl font-bold text-white mb-4">
          Welcome to JRL ChatFi!
        </h1>
      </div>
    </div>

  );
};

export default Dashboard;
