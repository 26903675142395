import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import RecommendationDetails from '../components/RecommendationDetail';
import RecommendationForm from '../components/RecommendationForm';
import RecommendationsList from '../components/RecommendationsList';


import { Recommendation, Recommendation2 } from '../interfaces/Interfaces';
import { useUser } from '../providers/UserProvider';

interface RecommendationPageProps {
    collapseSidebar: Function
}

const RecommendationsPage: React.FC<RecommendationPageProps> = ({ collapseSidebar }) => {
    const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
    const [selectedRecommedation, setSelectedRecommendation] = useState<Recommendation2>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const { user } = useUser()

    const fetchRecommendations = async () => {
        const accessToken = localStorage.getItem('access_token');

        try {
            if (user) {
                const response = await axios.get<Recommendation[]>(`https://jrlchatfi.atxlabs.ai/copilot/api/fetch-recommendations/${user?.user_id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setRecommendations(response.data);
                setLoading(false);
            }
        } catch (err) {
            console.error("Error fetching recommendations:", err);
            setError("Failed to load recommendations. Please try again later.");
            setLoading(false);
        }
    };

    const toggleSidebar = () => setIsCollapsed(!isCollapsed);


    useEffect(() => { fetchRecommendations() }, [user])

    useEffect(() => {
        collapseSidebar();
    }, [selectedRecommedation])

    useEffect(() => { collapseSidebar(); setIsCollapsed(true) }, [recommendations])

    const handleNewRecommendation = (newRecommendation: Recommendation2) => {
        setSelectedRecommendation(newRecommendation)
        fetchRecommendations();
    };

    return (
        <div className="flex h-full">
            <div className={`pl-4 pt-4 fixed h-full md:static bg-customDarkGrey backdrop-blur-sm bg-opacity-50 transition-all duration-300 ease-in-out ${isCollapsed ? 'w-0 opacity-0' : 'w-screen md:w-96 opacity-100'} overflow-hidden`}
            >
                <div className="flex-col mr-20 md:mr-0 bg-customDarkGrey h-full">
                    <div className="p-8">
                        <RecommendationForm onNewRecommendation={handleNewRecommendation} />
                    </div>

                    <div className="overflow-auto">
                        <RecommendationsList setSelectedRecommendation={setSelectedRecommendation} selectedRecommendation={selectedRecommedation} fetchRecommendations={fetchRecommendations} recommendations={recommendations} loading={loading} error={error} />
                    </div>
                </div>
            </div>
            <div className="z-10  text-white flex flex-col justify-center h-full mx-2">
                <div onClick={toggleSidebar} className="px-1 py-6 border-[1px] border-gray-800 rounded">
                    <FaAngleDoubleLeft className={`transition-transform duration-300 ${isCollapsed ? 'rotate-180' : ''}`} />
                </div>
            </div>
            {selectedRecommedation ? (
                <div className="w-full overflow-hidden -ml-6">
                    <RecommendationDetails recommendation={selectedRecommedation} />
                </div>
            ) : (
                <div className="flex justify-center items-center w-full h-full">
                    <p className="text-gray-500">Select A Recommendation to learn more</p>
                </div>
            )}
        </div>
    );
}

export default RecommendationsPage;
