import axios from 'axios'

export const login = async (username: string, password: string) => {

  const getCSRFToken = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; csrftoken=`);
    if (parts.length === 2) return parts!.pop()!.split(';').shift();
  };
  const response = await axios.post('https://jrlchatfi.atxlabs.ai/copilot/api/login/', { username, password }, {
    headers: {
      'X-CSRFToken': getCSRFToken() || '', // Add the CSRF token header
      'Content-Type': 'application/json',
    },
    withCredentials: true, // Ensure cookies are sent with the request
  })
  const { access, refresh } = response.data
  localStorage.setItem('access_token', access)
  localStorage.setItem('refresh_token', refresh)
  return response.data
}

export const logout = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}

export const isAuthenticated = () => !!localStorage.getItem('access_token')
