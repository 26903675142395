import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Recommendation, Recommendation2 } from '../interfaces/Interfaces';
import { FaTrash } from 'react-icons/fa';
import { formatDateTimeSmart } from '../utils';

interface RecommendationsProps {
    setSelectedRecommendation: Function,
    selectedRecommendation: Recommendation2 | undefined,
    fetchRecommendations: Function,
    recommendations: Recommendation[],
    loading: boolean,
    error: string | undefined,
}

const Recommendations: React.FC<RecommendationsProps> = ({ setSelectedRecommendation, selectedRecommendation, fetchRecommendations, recommendations, loading, error }) => {
    const [deleting, setDeleting] = useState<boolean>(false);
    const recoListRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (recoListRef.current) {
            recoListRef.current.scrollTop = recoListRef.current.scrollHeight;
        }

    }, [recommendations])

    const handleDelete = async (id: number) => {
        const accessToken = localStorage.getItem('access_token');
        if (!window.confirm("Are you sure you want to delete this recommendation?")) return;

        setDeleting(true);

        const getCSRFToken = () => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; csrftoken=`);
            if (parts.length === 2) return parts!.pop()!.split(';').shift();
        };


        try {
            await axios.delete(`https://jrlchatfi.atxlabs.ai/copilot/api/delete-recommendation/`, {
                data: { recommendationId: id },
                headers: {
                    'X-CSRFToken': getCSRFToken() || '',

                    'Authorization': `Bearer ${accessToken}`
                },
                withCredentials: true,

            });
            fetchRecommendations();
        } catch (err) {
            console.error("Error deleting recommendation:", err);
            alert("Failed to delete recommendation. Please try again later.");
        } finally {
            setDeleting(false);
        }
    };

    if (loading) return <p>Loading recommendations...</p>;
    if (error) return <p className='text-red-600 text-center'>{error}</p>;

    return (
        <div className="max-w-2xl mx-auto px-6 rounded-lg ">
            <div className="w-full">
                {recommendations.length === 0 ? (
                    <p className="text-gray-500 text-center text-lg italic">
                        No previous recommendations.
                    </p>
                ) : (
                    <ul
                        className="space-y-6 overflow-y-auto max-h-[40vh] flex flex-col custom-scrollbar"
                        ref={recoListRef}
                    >
                        {recommendations.map((rec) => (
                            <li
                                key={rec.id}
                                className="bg-customAlmostBlack rounded-lg shadow-md p-5 flex justify-between items-center transition-transform transform "
                            >
                                <div
                                    className={`cursor-pointer transition-colors duration-300 flex flex-col gap-2 ${selectedRecommendation && selectedRecommendation.id === rec.id
                                        ? "text-customGreen"
                                        : "text-white"
                                        }`}
                                    onClick={() => setSelectedRecommendation(rec)}
                                >
                                    <h3 className="text-xl font-semibold">
                                        {rec.reconname}
                                    </h3>
                                    <p className="text-sm text-gray-300">{rec.type}</p>
                                    <p className="text-sm text-gray-400 italic">{rec.sheet}</p>
                                    <p className="text-sm text-gray-500">{formatDateTimeSmart(rec.datetime)}</p>
                                </div>
                                <button
                                    className="bg-red-500 text-white hover:bg-red-600 px-3 py-2 rounded-full shadow-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={() => handleDelete(rec.id)}
                                    disabled={deleting}
                                >
                                    <FaTrash className="w-5 h-5" />
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>

    );
};

export default Recommendations;
