import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import QnAChat from './pages/QAChat';
import Login from './pages/Login';
import Register from './pages/Register';
import { fetchUserImage } from './services/Apis';
import RecommendationSteps from './pages/Recommendations';
import { useAuth } from './services/useAuth';
import PowerOff from './assets/turn-off.png';
import { UserProvider } from './providers/UserProvider';
import { Branding } from './components/Branding';
import Settings from './pages/Settings';

const App: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('dashboard');
  const [userImage, setUserImage] = useState<string | null>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const { isLoggedIn } = useAuth()


  useEffect(() => {
    const token = localStorage.getItem('access_token');
    setIsAuthenticated(!!token);
  }, []);


  useEffect(() => {
    if (isAuthenticated) {
      fetchUserImage(setUserImage);
    }
  }, [isAuthenticated]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      localStorage.removeItem('access_token');
      window.location.href = '/login';
      setIsAuthenticated(false);
    }
  };

  const collapseSidebar = () => {
    setSidebarCollapsed(true)
  }


  return (
    <Router>
      <UserProvider>
        <div className='flex-col min-h-screen bg-[#1a1a1a] max-w-screen overflow-auto no-scrollbar'>
          <div className="flex h-screen">
            {isAuthenticated && (
              <Sidebar activeTab={activeTab} onTabChange={handleTabChange} isCollapsed={sidebarCollapsed} setIsCollapsed={setSidebarCollapsed} />
            )}

            <div className="flex-1 overflow-auto">
              {isAuthenticated && (
                <div className="absolute top-2 right-2 md:top-4 md:right-4 bg-[#13EC13] rounded-full md:p-1">
                  {userImage ? (
                    <img
                      src={userImage}
                      alt="User"
                      className="h-10 w-10 md:w-12 md:h-12 rounded-full"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
                  )}
                  <button
                    onClick={handleLogout}

                    className="w-5 h-5 bg-gray-300 border-1 border-red-500 rounded-full absolute top-2 right-12 md:top-4 md:right-16 items-center justify-center hover:bg-red-600 transition  m-1"
                    title="Logout"
                  >
                    <img className="w-5 h-5" src={PowerOff} alt="logout" />
                  </button>
                </div>
              )}

              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
                <Route path="/recommendation" element={isLoggedIn ? <RecommendationSteps collapseSidebar={collapseSidebar} /> : <Navigate to="/login" />} />
                <Route path="/chatfi" element={isLoggedIn ? <QnAChat /> : <Navigate to="/login" />} />
                <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/home" />} />
                <Route path="/settings" element={isLoggedIn ? <Settings /> : <Navigate to="/login" />} />
                <Route path="/register" element={!isLoggedIn ? <Register /> : <Navigate to="/home" />} />
              </Routes>
            </div>
          </div>
          <Branding />
        </div>
      </UserProvider >
    </Router >


  );
};

export default App;
