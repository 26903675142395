// @ts-nocheck
import {
  useLLMOutput,
} from "@llm-ui/react";
import { markdownLookBack } from "@llm-ui/markdown";
import { useStreamExample } from "@llm-ui/react";
import { MarkdownComponent } from "./MarkdownComponent";

interface ChatBubbleProps {
  content: string;
}


const ChatBubble: React.FC<ChatBubbleProps> = ({
  content,
}) => {

  const { blockMatches } = useLLMOutput({
    llmOutput: content,
    blocks: [],
    fallbackBlock: {
      component: MarkdownComponent,
      lookBack: markdownLookBack(),
    },
    isStreamFinished: true
  });


  return (
    <div className="text-lg">
      {blockMatches.map((blockMatch, index) => {
        const Component = blockMatch.block.component;
        return <Component key={index} blockMatch={blockMatch} />
      })}

    </div>
  );
};

export default ChatBubble;
