import axios from "axios";
import { DummyData, ImageData } from "../interfaces/Interfaces";
// src/services/Apis.tsx

const api = axios.create({
  baseURL: 'https://jrlchatfi.atxlabs.ai/copilot/api/',
});

// Add a request interceptor to include the JWT token in headers
api.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optionally, add a response interceptor to handle 401 errors globally
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Handle the 401 error (Unauthorized)
      console.error("Unauthorized - Token may be invalid or expired.");
      // Optional: Redirect to login or refresh token logic can go here
    }
    return Promise.reject(error);
  }
);

export default api;


//getTrendingImages
export const fetchImages = async (): Promise<ImageData[]> => {
  try {
    const response = await axios.get<ImageData[]>('https://jrlchatfi.atxlabs.ai/copilot/api/images/');
    return response.data;
  } catch (error) {
    console.error('Error fetching images:', error);
    throw new Error('Failed to fetch images');
  }
};

//getRecentImages
export const fetchRecentImages = async (): Promise<ImageData[]> => {
  try {
    const response = await axios.get<ImageData[]>('https://jrlchatfi.atxlabs.ai/copilot/api/recentimages/');
    return response.data;
  } catch (error) {
    console.error('Error fetching images:', error);
    throw new Error('Failed to fetch images');
  }
};

//getStatsData
export const fetchUserData = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setUserData: React.Dispatch<React.SetStateAction<DummyData | null>>
) => {
  try {
    setLoading(true);
    const response = await axios.get('https://jrlchatfi.atxlabs.ai/copilot/api/dashboard/');
    setUserData(response.data);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching user data:', error);
    setLoading(false);
    setUserData(null);
  }
};

//getSummeryDataForDashboard
export const fetchSummaryData = async (setPerformanceData: React.Dispatch<React.SetStateAction<any[]>>, setChartLoading: React.Dispatch<React.SetStateAction<boolean>>, setChartError: React.Dispatch<React.SetStateAction<string | null>>) => {
  axios.get('https://jrlchatfi.atxlabs.ai/copilot/api/summery-data/')
    .then(response => {
      setPerformanceData(response.data);
      setChartLoading(false);
    })
    .catch(error => {
      setChartError('Failed to load performance data');
      setChartLoading(false);
    });
}
//getNotifications&Alerts
export const getNotif = (setNotifications: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>) => {
  axios
    .get('https://jrlchatfi.atxlabs.ai/copilot/api/notifications/')
    .then((response) => {
      setNotifications(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching notifications:', error);
      setError('Failed to load notifications. Please try again later.');
      setLoading(false);
    });
}

export const fetchTargetGraphData = (setPerformanceData: React.Dispatch<React.SetStateAction<number[]>>,
  setCurrentPerformance: React.Dispatch<React.SetStateAction<number>>,
  setAveragePerformance: React.Dispatch<React.SetStateAction<number>>,
  setGoal: React.Dispatch<React.SetStateAction<number>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>

) => {
  axios.get('https://jrlchatfi.atxlabs.ai/copilot/api/targets-data/')
    .then((response) => {
      setPerformanceData(response.data.performanceData);
      setCurrentPerformance(response.data.currentPerformance);
      setAveragePerformance(response.data.averagePerformance);
      setGoal(response.data.goal);
      setLoading(false);
    })
    .catch((error) => {
      setError('Failed to fetch target data');
      setLoading(false);
    });
}

export const fetchUserImage = async (
  setUserImage: React.Dispatch<React.SetStateAction<string | null>>
) => {
  try {
    const response = await fetch('https://jrlchatfi.atxlabs.ai/copilot/api/profile-image/');
    const data = await response.json();
    // TODO: Make this robust
    setUserImage("https://atxlabs.ai/wp-content/uploads/2024/04/cropped-ATXLabs_Logogram_Circle_Filled_CoG-192x192.png");
  } catch (error) {
    console.error('Error fetching user image:', error);
  }
};


