// src/hooks/useAuth.ts
import { useState, useEffect } from 'react'
import { isAuthenticated } from './AuthServices'

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isAuthenticated())

  useEffect(() => {
    setIsLoggedIn(isAuthenticated())
  }, [])

  return {
    isLoggedIn,
    setIsLoggedIn,
  }
}
