import React from "react";


export const Branding: React.FC = () => {

  return (
    <div className="fixed text-white text-center flex justify-center -mt-8 pb-2 pt-1 w-full bg-customAlmostBlack z-[500] opacity-100 bg-opacity-100">
      <h1>Powered by &nbsp;</h1>
      <img src="https://atxlabs.ai/wp-content/uploads/2024/04/ATXLabs_Logotype_Transparent_Colour-WhiteGreen.svg" className="w-20" />
    </div>
  )

} 
