import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes, FaHome, FaComment, FaChartLine } from 'react-icons/fa';
import { IoMdSettings } from "react-icons/io";
import { NavLink } from 'react-router-dom';

export interface SidebarProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
  isCollapsed: boolean;
  setIsCollapsed: Function;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, onTabChange, isCollapsed, setIsCollapsed }) => {

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`z-20 fixed mt-1 md:static md:min-h-screen bg-[#101010] text-white rounded-r-3xl flex flex-col justify-between shadow-lg transition-all duration-300 ease-in-out overflow-hidden ${isCollapsed ? 'w-12 h-12 md:min-w-28 md:w-28 md:p-8' : 'p-8 h-screen w-screen md:min-w-80 md:w-80'}`}

    >
      <div className="relative">
        <div className={`flex mb-12 h-12 items-center justify-${isCollapsed ? "center" : "start"}`}>
          <button
            onClick={toggleSidebar}
            className="text-white z-30 items-center "
          >
            <h2 className="text-2xl">
              {isCollapsed ? <FaBars /> : <FaTimes />}
            </h2>
          </button>
          <h2
            className={`text-3xl whitespace-nowrap font-bold tracking-wide text-white transition-all duration-300 ${isCollapsed ? 'text-center' : ''}`}
          >
            {!isCollapsed ? 'JRL ChatFi' : ''}
          </h2>
        </div>

        <nav>
          <ul className="space-y-6 whitespace-nowrap overflow-hidden">
            <li>
              <NavLink
                to="/home"
                className={({ isActive }) =>
                  `font-semibold text-xl flex items-center ${isActive
                    ? 'text-[#13EC13]'
                    : 'text-white hover:text-[#13EC13]'
                  } justify-${isCollapsed ? "center" : "start"}`
                }
                onClick={() => { onTabChange('home'); setIsCollapsed(true) }}
              >
                <span className="flex justify-center">
                  <FaHome />
                </span>
                <span
                  className={`transition-opacity duration-300 m-0 ${isCollapsed ? 'opacity-0 w-0' : 'opacity-100 mx-4'}`}
                >
                  Home
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/chatfi"
                className={({ isActive }) =>
                  `font-semibold text-xl flex items-center ${isActive
                    ? 'text-[#13EC13]'
                    : 'text-white hover:text-[#13EC13]'
                  } justify-${isCollapsed ? "center" : "start"}`
                }

                onClick={() => { onTabChange('qna'); setIsCollapsed(true) }}
              >
                <span className="flex justify-center">
                  <FaComment />
                </span>
                <span
                  className={`transition-opacity duration-300 ${isCollapsed ? 'opacity-0 w-0' : 'opacity-100 mx-4'}`}
                >
                  ChatFi
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/recommendation"
                className={({ isActive }) =>
                  `font-semibold text-xl flex items-center ${isActive
                    ? 'text-[#13EC13]'
                    : 'text-white hover:text-[#13EC13]'
                  } justify-${isCollapsed ? "center" : "start"}`
                }
                onClick={() => { onTabChange('recommendation'); setIsCollapsed(true) }}
              >
                <span className="flex justify-center">
                  <FaChartLine />
                </span>
                <span
                  className={`transition-opacity duration-300 ${isCollapsed ? 'opacity-0 w-0' : 'opacity-100 mx-4'}`}
                >
                  MF Recommendation
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  `font-semibold text-xl flex items-center ${isActive
                    ? 'text-[#13EC13]'
                    : 'text-white hover:text-[#13EC13]'
                  } justify-${isCollapsed ? "center" : "start"}`
                }
                onClick={() => { onTabChange('recommendation'); setIsCollapsed(true) }}
              >
                <span className="flex justify-center">
                  <IoMdSettings />
                </span>
                <span
                  className={`transition-opacity duration-300 ${isCollapsed ? 'opacity-0 w-0' : 'opacity-100 mx-4'}`}
                >
                  Settings
                </span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      <div className="text-gray-400 text-sm flex flex-col">
        <img
          src='https://www.jrladdha.in/images/jrlindialogo.png'
          className="mb-5 m-10 md:max-w-full h-auto"
          alt="JRL ChatFi Logo"
        />
        <div className={`text-center h-24 transition-opacity duration-300 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}>
          <p>&copy; 2024 JRL ChatFi</p>
          <p>All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

